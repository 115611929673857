// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/error-boundary.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/error-boundary.tsx");
  import.meta.hot.lastModified = "1727806535411.0706";
}
// REMIX HMR END

import { isRouteErrorResponse, useParams, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { getErrorMessage } from '~/services/misc.ts';
export function GeneralErrorBoundary({
  defaultStatusHandler = ({
    error
  }) => {
    return <p>
				{error.status} {error.data}
			</p>;
  },
  statusHandlers,
  unexpectedErrorHandler = error => <p>{getErrorMessage(error)}</p>
}) {
  _s();
  const error = useRouteError();
  const params = useParams();
  // const matches = useMatches()

  captureRemixErrorBoundaryError(error);
  console.info(error);
  if (typeof document !== 'undefined') {}
  return <div className="text-h2 container flex content-center items-center justify-center p-20">
			<div className="bg-red-100 p-4 text-2xl drop-shadow-xl	">
				{isRouteErrorResponse(error) ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
        error,
        params
      }) : unexpectedErrorHandler(error)}
			</div>
		</div>;
}
_s(GeneralErrorBoundary, "6EFrFbtadsJk9+AfSjNnBBmTVkA=", false, function () {
  return [useRouteError, useParams];
});
_c = GeneralErrorBoundary;
var _c;
$RefreshReg$(_c, "GeneralErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;